import React, { useState } from "react"
import BasicLayout from "../layouts/basic-layout"
import { Row, Col, Image } from "react-bootstrap"
import styled from "styled-components"
import Card from "../components/news-card"
import LeftIcon from "../images/left.png"

const Container = styled.div`
  position: relative;
`

const FetchMore = styled.div`
  margin-top: 16px;
  height: 30px;
  width: 100%;
  text-align: center;

  img {
    height: 30px;
    width: 30px;
    transform: rotate(-90deg);
  }
`

const generateNews = () => ({
  id: Date.now() + Math.round(Math.random() * 10000),
  cover: "http://cdn.jerryshi.com/gtkj/20200109115209.png",
  date: "2019.8.8",
  title: "城建物业开展丰富多彩的文体活动",
  digest:
    "城建物业开展丰富多彩的文体活动城建物业开展丰富多彩的文体活动城建物业开展丰富多彩的文体活动城建物业开展丰富多彩的文体活动城建物业开展丰富多彩的文体活动城建物业开展丰富多彩的文体活动",
})

const data = [
  {
    id: 101,
    cover: "http://cdn.jerryshi.com/gtkj/20200109115209.png",
    date: "2019.8.8",
    title: "城建物业开展丰富多彩的文体活动",
    digest:
      "城建物业开展丰富多彩的文体活动城建物业开展丰富多彩的文体活动城建物业开展丰富多彩的文体活动城建物业开展丰富多彩的文体活动城建物业开展丰富多彩的文体活动城建物业开展丰富多彩的文体活动",
  },
  {
    id: 102,
    cover: "http://cdn.jerryshi.com/gtkj/20200109115209.png",
    date: "2019.8.8",
    title: "城建物业开展丰富多彩的文体活动",
    digest:
      "城建物业开展丰富多彩的文体活动城建物业开展丰富多彩的文体活动城建物业开展丰富多彩的文体活动城建物业开展丰富多彩的文体活动城建物业开展丰富多彩的文体活动城建物业开展丰富多彩的文体活动",
  },
  {
    id: 103,
    cover: "http://cdn.jerryshi.com/gtkj/20200109115209.png",
    date: "2019.8.8",
    title: "城建物业开展丰富多彩的文体活动",
    digest:
      "城建物业开展丰富多彩的文体活动城建物业开展丰富多彩的文体活动城建物业开展丰富多彩的文体活动城建物业开展丰富多彩的文体活动城建物业开展丰富多彩的文体活动城建物业开展丰富多彩的文体活动",
  },
  {
    id: 104,
    cover: "http://cdn.jerryshi.com/gtkj/20200109115209.png",
    date: "2019.8.8",
    title: "城建物业开展丰富多彩的文体活动",
    digest:
      "城建物业开展丰富多彩的文体活动城建物业开展丰富多彩的文体活动城建物业开展丰富多彩的文体活动城建物业开展丰富多彩的文体活动城建物业开展丰富多彩的文体活动城建物业开展丰富多彩的文体活动",
  },
  {
    id: 105,
    cover: "http://cdn.jerryshi.com/gtkj/20200109115209.png",
    date: "2019.8.8",
    title: "城建物业开展丰富多彩的文体活动",
    digest:
      "城建物业开展丰富多彩的文体活动城建物业开展丰富多彩的文体活动城建物业开展丰富多彩的文体活动",
  },
  {
    id: 106,
    cover: "http://cdn.jerryshi.com/gtkj/20200109115209.png",
    date: "2019.8.8",
    title: "城建物业开展丰富多彩的文体活动",
    digest:
      "城建物业开展丰富多彩的文体活动城建物业开展丰富多彩的文体活动城建物业开展丰富多彩的文体活动",
  },
]

const Page = () => {
  const [news, set] = useState(data)
  return (
    <BasicLayout
      title="资讯中心"
      bannerImg="http://maps.yncce.cn/website/news.png"
    >
      <Container>
        <Row>
          {news.map(item => (
            <Col key={item.id} md={4} sx={12} style={{ marginBottom: 38 }}>
              <Card {...item} />
            </Col>
          ))}
        </Row>
        <FetchMore
          onClick={() => {
            set(prev => prev.concat(Array(6).fill(generateNews())))
          }}
        >
          <Image src={LeftIcon} />
        </FetchMore>
      </Container>
    </BasicLayout>
  )
}

export default Page
