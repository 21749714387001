import React from "react"
import { Card } from "react-bootstrap"
import styled from "styled-components"

const Digest = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  letter-spacing: 1.2px;
`

const CardCom = ({ cover, date, title, digest }) => (
  <Card>
    <Card.Img variant="top" src={cover} />
    <Card.Body>
      <Card.Title>
        <p style={{ marginBottom: 10, fontSize: 24 }}>{date}</p>
        <p style={{ color: "#333", fontSize: '15pt', overflow: "hidden" }}>
          {title}
        </p>
      </Card.Title>
      <Card.Text style={{ fontSize: 13 }}>
        <Digest>{digest}</Digest>
      </Card.Text>
    </Card.Body>
  </Card>
)

export default CardCom
